import {
    createApp
} from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import 'vue3-virtual-scroller/dist/vue3-virtual-scroller.css';
import { RecycleScroller } from 'vue3-virtual-scroller';
const token = localStorage.getItem('jwtToken');
if (token) {
  axios.defaults.headers.common['Authorization'] = `${token}`;
}
const app = createApp(App);

for (const [name, comp] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, comp);
}

app.component('RecycleScroller', RecycleScroller);
app.use(ElementPlus).use(store).use(router).mount('#app');
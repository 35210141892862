import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/nav.vue'),
    children:[
        {
          path: 'market',
          name: 'market',
          component: () => import(/* webpackChunkName: "about" */ '../components/marketing/marketing.vue'),
          children:[
            {
              path: '', // 空路径，用于重定向到 markindex
              name:'market-child',
              redirect: { name: 'markindex' }
            },
            {
              path: 'index',
              name: 'markindex',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/index.vue')
            },
            {
              path: 'edit/:id',
              name: 'markedit',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/edit.vue'),
              props: true

            },
            {
              path: 'edit_rep/:id',
              name: 'markedit_rep',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/edit_rep.vue'),
              props: true

            },
            {
              path: 'add',
              name: 'markadd',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/add.vue')
            },
            {
              path: 'classification/:tag',
              name: 'markclassification',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification.vue'),
              props: true
            },
            {
              path: 'classification_c/:cou',
              name: 'markclassification_c',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_c.vue'),
              props: true
            },
            {
              path: 'classification_m/:mkt',
              name: 'markclassification_m',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_m.vue'),
              props: true
            },
            {
              path: 'classification_d/:day',
              name: 'markclassification_d',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_d.vue'),
              props: true
            },
            {
              path: 'classification_w/:week',
              name: 'markclassification_w',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_w.vue'),
              props: true
            },
            {
              path: 'classification_y/:yue',
              name: 'markclassification_y',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_y.vue'),
              props: true,
              
            },
            {
              path: 'classification_date/:datetime',
              name: 'markclassification_date',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_date.vue'),
              props: true
            },
            {
              path: 'classification_yw/:yw',
              name: 'markclassification_yw',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_yw.vue'),
              props: true
            },
            {
              path: 'classification_fb/:fb',
              name: 'markclassification_fb',
              component:() => import(/* webpackChunkName: "about" */ '../components/marketing/classification_fb.vue'),
              props: true
            },
          ]
        },
        {
          path: 'collect',
          name: 'collect',
          component: () => import(/* webpackChunkName: "about" */ '../views/collect.vue'),
          children:[
            {
              path: '', // 空路径，用于重定向到 markindex
              name:'collect-child',
              redirect: { name: 'collectindex' }
            },
            {
              path: 'index',
              name: 'collectindex',
              component:() => import(/* webpackChunkName: "about" */ '../components/collect/index.vue')
            },
          ]
        }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect:'/',
  },
]
// createWebHistory()
const router = createRouter({
  history: createWebHashHistory(),
  routes
})




export default router
